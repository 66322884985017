<template>
    <div>
        <navbar
        :items="navbarItems"
        :fonticon="pageIcon"
        :navbartitle="pageTitle"
        :navbardescription="pageDescription"
        />
      
      <data-table
        :data-source="packs"
        :headers="headers"
        table-class="table-striped table-hover"
      >
        <template slot="actions">
          <a
            href="javascript:void(0)"
            class="btn btn-outline-primary btn-icon"
            @click.prevent="initForm"
            data-toggle="modal"
            data-target="#add-pack"
          >
            <i class="icofont icofont-plus" />
          </a>
        </template>
      </data-table>
      <modal
        title="Ajout de Pack"
        id="add-pack"
        :footer="false"
        size="large"
      >
        <form
          class="form-horizontal"
          @submit.prevent="addingPack"
        >
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-libelle">Libelle</label>
                    <input
                        type="text"
                        id="add-libelle"
                        name="add-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-services">Services</label>
                    <select v-select="{placeholder: 'Selectionnez les service du pack'}" class="form-control" name="add-services" id="add-services" v-model="services" multiple>
                        <option></option>
                        <option v-for="(s, index) in allServices" :key="index" :value="s.uid">{{ s.libelle }}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label for="add-amount">Prix</label>
                    <input
                        type="text"
                        id="add-amount"
                        name="add-amount"
                        v-model="amount"
                        class="form-control"
                    >
                </div>
            </div>
            <div class="col-8">
                <div class="form-group">
                    <label for="add-agent">Agent responsable</label>
                    <select v-select="{placeholder: 'Selectionnez l\'agent en charge du pack'}" class="form-control" name="add-agent" id="add-agent" v-model="agent">
                        <option></option>
                        <option v-for="(a, index) in listAgents" :key="index" :value="a.uid">{{ a.fullname }}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
                <add-photo :index="0"/>
            </div>
            <div class="col-8">

            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="add-description">Description</label>
                    <textarea
                        type="text"
                        id="add-description"
                        name="add-description"
                        v-model="description"
                        class="form-control"
                        rows="5"
                    ></textarea>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Ajouter
              </button>
            </div>
          </div>
        </form>
      </modal>
      <modal
        title="Modification du Pack" 
        id="update-pack"
        size="large"
        :footer="false"
      >
        <form
          class="form-horizontal"
          @submit.prevent="editingPack"
        >
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="edit-libelle">Libelle</label>
                    <input
                        type="text"
                        id="edit-libelle"
                        name="edit-libelle"
                        v-model="libelle"
                        class="form-control"
                    >
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="update-services">Services</label>
                    <select v-select="{placeholder: 'Selectionnez les service du pack'}" class="form-control" name="update-services" id="update-services" v-model="services" multiple>
                        <option></option>
                        <option v-for="(s, index) in allServices" :key="index" :value="s.uid">{{ s.libelle }}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-4">
                <div class="form-group">
                    <label for="update-amount">Prix</label>
                    <input
                        type="text"
                        id="update-amount"
                        name="update-amount"
                        v-model="amount"
                        class="form-control"
                    >
                </div>
            </div>
            <div class="col-8">
                <div class="form-group">
                    <label for="update-agent">Agent responsable</label>
                    <select v-select="{placeholder: 'Selectionnez l\'agent en charge du pack'}" class="form-control" name="update-agent" id="update-agent" v-model="agent">
                        <option></option>
                        <option v-for="(a, index) in listAgents" :key="index" :value="a.uid">{{ a.fullname }}</option>
                    </select>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-5">
                <add-photo :index="0"/>
            </div> 
            <div class="col-8">

            </div>
          </div>
          <div class="row">
            <div class="col-12">
                <div class="form-group">
                    <label for="edit-description">Description</label>
                    <textarea
                        rows="5"
                        type="text"
                        id="edit-description"
                        name="edit-description"
                        v-model="description"
                        class="form-control"
                    ></textarea>
                </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12 text-right">
              <button
                type="submit"
                class="btn btn-primary"
              >
                Enregistrer
              </button>
            </div>
          </div>
        </form>
      </modal>
    </div>
  </template>
  
  <script>
  import {mapGetters, mapMutations} from 'vuex'
  import DataTable from '../../../components/dataTable/local.vue'
  import addPhoto from '../../../components/market/addProductPhoto.vue'
  import {TEXT_TYPE, COMPONENT_TYPE, NUMERIC_TYPE } from '../../../components/dataTable/dataType'
  import Modal from '../../../components/modal.vue'
import Navbar from '../../../components/navbar.vue'
import { ADD_PACK, UPDATE_PACK } from '../../../graphql/pack'
import { TYPE_ADMIN, TYPE_ASSISTANT_VETERINAIRE, TYPE_ASSISTANT_VETERINAIRE_MAJOR, TYPE_LABORATOIRE, TYPE_SUPER_ADMIN, TYPE_TECHNICIEN_IMAGERIE, TYPE_VETERIANIRE } from '../../../constants/app'
  const Actions = () => import('../../../components/admin/pack/packActions.vue')
  const PackPhoto = () => import ('../../../components/admin/pack/packPhoto.vue')
  const PackServices = () => import('../../../components/admin/pack/parckService.vue')
  const PackAgent = () => import ('../../../components/admin/pack/packAgent.vue')
  export default {
      components: { Navbar, DataTable, Modal, addPhoto},
      data(){
          return {
            navbarItems: [
              {
                libelle: 'Pack Managment'
              }
            ],
            pageIcon: 'la-gift',
            pageTitle: 'Pack managment',
            pageDescription: 'Gestion des Packs d\'abonnement sur sur CASEAC',
              libelle: null,
              code: null,
              description: null,
              services: [],
              amount: 0,
              agent: null,
              photo: null,
              has_error: false,
              error_msg: null
          }
      },
      watch: {
        selectedObject: {
              handler(){
                  if(this.selectedObject !== null) {
                      this.libelle = this.selectedObject.libelle
                      this.code = this.selectedObject.code
                      this.description = this.selectedObject.description
                      this.services = JSON.parse(this.selectedObject.services)
                      this.amount = this.selectedObject.amount
                      this.agent = this.selectedObject.agent
                      this.photo = JSON.parse(this.selectedObject.photo)
                    }
              },
              deep: true
          }
      },
      methods: {
          ...mapMutations({
              done: 'DONE', 
              setSelectedObject: 'SET_SELECTED_OBJECT',
              setPhotos: 'market/SET_PRODUCT_PHOTOS'
          }),
          initForm(){
              this.setSelectedObject(null)
              this.libelle = null
              this.code = null
              this.description = null
              this.services = []
              this.amount = 0
              this.agent =  null
              this.photo = null
              this.setPhotos([])
          },
          addingPack(){
              let data = {
                  libelle: this.libelle,
                  description: this.description,
                  services: JSON.stringify(this.services),
                  amount: parseFloat(this.amount),
                  agent : this.agent,
                  photo: this.photos.length > 0 ? JSON.stringify(this.photos[0]) : null
              }
              console.log(this.photos)
              this.$apollo.mutate({
                  mutation: ADD_PACK,
                  variables: {
                      "pack": {
                          ...data
                      }
                  },
                  update: ({data}) => {
                      console.log(`Pack add successfully on uid ${data.addMarketTypeObject}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          },
          editingPack(){
              let data = {
                  libelle: this.libelle,
                  description: this.description,
                  services: JSON.stringify(this.services),
                  amount: parseFloat(this.amount),
                  agent : this.agent,
                  photo: this.photos.length > 0 ? JSON.stringify(this.photos[0]) : null
              }
              this.$apollo.mutate({
                  mutation: UPDATE_PACK,
                  variables: {
                      "pack": {
                          ...data
                      }, 
                      "uid": this.selectedObject.uid
                  },
                  update: () => {
                      console.log(`Type product  ${this.selectedObject.libelle} edit successfully on uid ${this.selectedObject.uid}`)
                  }
                }).then(() => {
                  this.initForm()
                  this.done()
              }).catch((error) => {
                  console.error(error)
                  this.has_error = true
                  this.error_msg = error
              })
          }
      },
      computed: {
          ...mapGetters({
              packs: 'packs/packs',
              allServices: 'packs/services',
              users: 'auth/users',
              typeComptes: 'auth/typeComptes',
              selectedObject: 'selectedObject',
              photos: 'market/productPhotos'
          }),
          listAgents(){
            let types = [...this.typeComptes]
            .filter(item => [
                    TYPE_VETERIANIRE, 
                    TYPE_ASSISTANT_VETERINAIRE, 
                    TYPE_ASSISTANT_VETERINAIRE_MAJOR,
                    TYPE_LABORATOIRE,
                    TYPE_TECHNICIEN_IMAGERIE,
                    TYPE_ADMIN,
                    TYPE_SUPER_ADMIN
                ].includes(item.code)
            ).map((item) =>  item.uid )
            return [...this.users]
            .filter(item => types.includes(item.typeCompte) && item.active)
            .sort((a,b) => a.fullname.localeCompare(b.fullname))
          },
          headers(){
              return [
                  {label: 'ID', name: 'uid', type: TEXT_TYPE},
                  {label: 'Photo', name: 'photo', type: COMPONENT_TYPE, component: PackPhoto},
                  {label: 'Libelle', name: 'libelle', type: TEXT_TYPE},
                  {label: 'Code', name: 'code', type: TEXT_TYPE},
                  {label: 'services', name: 'services', type: COMPONENT_TYPE, component: PackServices},
                  {label: 'Prix (fCFA)', name: 'amount', type: NUMERIC_TYPE},
                  {label: 'Agent en charge', name: 'agent', type: COMPONENT_TYPE, component: PackAgent},
                //   {label: 'Description', name: 'description', type: TEXT_TYPE},
                  {label: 'Actions', name: 'action', type: COMPONENT_TYPE, component: Actions},
              ]
          }
      }
  }
  </script>
  
  <style>
  
  </style>